<template>
  <div class="container">
    <div class="e-top">
        <search/>
        <a class="btn btn-success btn-sm btn-lg w-100 mt-1 " href="/jobs/add" @click="submit">发布招聘信息</a>
    </div>
    <div class="bg-white p-2 px-4 radius-5 my-2" v-for="(job,code) in data" :key="code">
      <router-link class="text-decoration-none" :to="'/jobs/info/' + job.id">
        <div class="row border-bottom">
            <div class="col px-0 align-self-start" :id="'name' + job.id">
              {{ job.name }}
            </div>
            <div class="col px-0 text-r">
              <span style="color: red">{{ SALARYINDEX[job.salarys] }}</span>
            </div>
        </div>
      </router-link>
      <div class="row">
          
        <div class="col-9 mt-1 p-0">
          <p class="fs-8 m-0 text-muted">
            {{ JOBS_SCHOOLING[job.schooling] }} | {{ AGEINDEX[job.ages] }} 岁 | 招 {{ job.apply_for_job }} /{{ job.people_number }}人
          </p>
          <p class="fs-8 m-0 text-truncate">
            <span class="bg-97 mx-1 text-muted" v-for="(value, code) in job.tags" :key="code">{{ ADVANTAGE[value] }}</span>
          </p>
        </div>

        <div class="col-3 p-0 text-r">
          <p class="fs-8 text-muted">{{ job.create_time }} </p>
        </div>
      </div>


      <div class="row my-2">
        <div class="col-1 p-0">
          <img :src="job.company.logo" width="35" />
        </div>

        <div class="col">
          <p class="m-0 fs-8"> {{ job.company.name }} </p>
          <p class="m-0 fs-7 text-muted">{{ NATURE[job.company.nature]}} | {{ COMPANY_TYPE[job.company.company_type]}} | {{ job.company.scale }}人</p>
        </div>
      </div>

      <div class="bg-light mt-2 text-truncate rounded-lg d-flex justify-content-center align-content-center">
          <a :href="`/jobs/edit/${job.id}`" class="mx-2">修 改</a>
          <a :href="`/jobs/settlement?id=${job.id}`" class="mx-2">费 用</a>
          <a class="mx-2" @click="jobstatus(job.id)">{{job.for_status == 0 ? '停 招' : '开 招'}}</a>
          <a :href="'/tool/applys/info?job_id=' + job.id" class="mx-2">投递者</a>
          <a class="mx-1" @click="deleteJob(job.id)">删 除</a>
      </div>
    </div>


    <div class="">
      <a
        class="showinfo"
        href="javascript:;"
        title="发布职位"
      >
        您还没有发招聘职位，点击这里去发布一下吧．
      </a>
      
    </div>
    
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { COMPANY_TYPE, NATURE, JOBS_TYPE, SALARYINDEX, ADVANTAGE, SCHOOLING, AGEINDEX, JOBS_SCHOOLING } from '@/lib/data'
export default {
  name: "jobsIndex",
  setup() {
    const data = ref([])
    const stat = ref()

    const a = 10

     for(var i=0; i<a; i++){
      data.value.push(
        {
          id:100 + i,
          name: "人事主管" + i,
          type_id:0,//岗位类型 技工、普工--->jobs_type
          content: "这是一个测试的数据",
          salarys: 1,//综合工资
          tags: [1,3,0],//包吃包住、不穿无尘衣
          schooling: 0,
          ages: 1,
          address: "这是一个个个的人",
          interview_time: "周一至周五,下午2-3点",
          people_number: 1000, //招聘人数
          apply_for_job: 588,
          contact: "陈一一",
          contact_tel: 180180180888,
          create_time:"2022-02-20",
          for_status: 0, //在招状态  0 在招， 1停招
          company: {
            id: 1001,
            name: "一二一通信苏州有限公司",
            logo: "/imgs/logo.jpg",
            company_type: 1,
            nature: 1,
            scale: 10000
          }
        }
      )
    }
//修改开招停招
    function jobstatus(id) {
      if(stat.value == 0) {
        stat.value = 1
      } else {
        stat.value = 0
      }
      Axios.put('http://localhost:5000/jobs', {id: id, for_status: stat.value}).then((response) => {
        if(response.data.code == 0) {
           for(const index in data.value) {
             if(data.value[index].id == id){
               data.value[index].for_status = stat.value
               return
             }
           }
           
        }
      })
    }
//获取数据
    function getData() {
      Axios.get('http://localhost:5000/jobs').then((response) => {
        if(response.data.code == 0){
          // data.value = response.data.data
        }
      })
    }
    onMounted(()=> {
      getData()
      stat.value = data.value.for_status
    })
  function deleteJob(id) {
        var a = document.getElementById('name' + id).innerHTML
        if(confirm('你确定要删除' + a + '吗？')) {
          Axios.delete('http://localhost:5000/jobs/' + id).then((Response) => {
             if(Response.data.code == 0) {
               for (const index in data.value) {
                 if(data.value[index].id == id) {
                  data.value.splice(index, 1)
                 }
               }
               alert('删除成功')
             }
          })
        }
    }
    return {
      jobstatus,
      deleteJob,
      COMPANY_TYPE,
      NATURE,
      JOBS_TYPE,
      SALARYINDEX,
      ADVANTAGE,
      SCHOOLING,
      AGEINDEX,
      JOBS_SCHOOLING,
      data
    }
  }
};
</script>

<style>
</style>